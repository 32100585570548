import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import {
  AssetsFindOptions,
  FleetAssetCreateUpdateResponse,
} from '../models/asset-management'
import { Observable, map } from 'rxjs'
import { FleetVehicle } from '@boosterfuels/types/types-ts'
import { FleetAsset } from '@fleet-customer/shared/types'

@Injectable({ providedIn: 'root' })
export class FleetVehicleApi {
  constructor(private http: HttpClient) {}

  find(findOptions: AssetsFindOptions) {
    return this.http.get<{ count: number; results: FleetAsset[] }>(
      `/FleetVehicle/find`,
      {
        params: setParams(new HttpParams(), findOptions),
      }
    )
  }

  archive(fleetVehicleId: string): Observable<FleetAsset> {
    return this.http.put<FleetAsset>(
      `/FleetVehicle/archive/${fleetVehicleId}`,
      {}
    )
  }

  unarchive(fleetVehicleId: string): Observable<FleetAsset> {
    return this.http.put<FleetAsset>(
      `/FleetVehicle/unarchive/${fleetVehicleId}`,
      {}
    )
  }

  downloadTemplate(
    fleetAccountId: string,
    assetType: 'VEHICLE' | 'EQUIPMENT'
  ): Observable<{ buffer: ArrayBuffer; name: string }> {
    const params = new HttpParams({
      fromObject: { fleetAccountId, assetType },
    })
    return this.http
      .get(`/FleetVehicle/template`, {
        params,
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response) => {
          return {
            buffer: response.body,
            name: getFilenameFromContentDisposition(
              response.headers.get('content-disposition')
            ),
          }
        })
      )
  }

  bulkUploadVehiclesReview(
    fleetAccountId: string,
    file: File,
    shouldReplaceVehicleList: boolean
  ): Observable<BulkUploadResponse> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append(
      'shouldReplaceVehicleList',
      shouldReplaceVehicleList.toString()
    )
    formData.append('fleetAccountId', fleetAccountId)
    return this.http.post<BulkUploadResponse>(
      `/FleetVehicle/bulkUploadVehicles/review`,
      formData
    )
  }

  bulkUploadVehicles(
    fleetAccountId: string,
    file: File,
    shouldReplaceVehicleList: boolean
  ): Observable<BulkUploadResponse> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append(
      'shouldReplaceVehicleList',
      shouldReplaceVehicleList.toString()
    )
    formData.append('fleetAccountId', fleetAccountId)
    return this.http.post<BulkUploadResponse>(
      `/FleetVehicle/bulkUploadVehicles`,
      formData
    )
  }

  findById(_id: string) {
    return this.http.get<FleetAsset>(`/FleetVehicle/${_id}`)
  }

  update(
    fleetVehicleId: string,
    fleetVehicle: Partial<FleetVehicle>
  ): Observable<FleetAssetCreateUpdateResponse> {
    return this.http.put<FleetAssetCreateUpdateResponse>(
      `/FleetVehicle/${fleetVehicleId}`,
      fleetVehicle
    )
  }

  getImageUploadParams(fleetAccountId: string) {
    return this.http.get<Record<string, string>>(
      `/FleetVehicle/imageUploadParams/${fleetAccountId}`
    )
  }

  plateToVin(
    plate: string,
    state: string
  ): Observable<{ vin: string | null; isError: boolean }> {
    return this.http.get<{ vin: string | null; isError: boolean }>(
      `/FleetVehicle/plateToVin`,
      {
        params: { plate, state },
      }
    )
  }

  reviewCreateAsset(
    fleetAccountId: string,
    dto: Partial<FleetVehicle>
  ): Observable<{
    fleetAsset?: FleetVehicle
    isError: boolean
    errorMessage: string
  }> {
    return this.http.post<any>(`/FleetVehicle/reviewCreateAsset`, dto, {
      params: { fleetAccountId },
    })
  }

  createAsset(
    fleetAccountId: string,
    dto: Partial<FleetVehicle>,
    pendingFleetVehicleId?: string
  ): Observable<FleetVehicle> {
    const params = {
      fleetAccountId,
    }
    if (pendingFleetVehicleId) {
      params['pendingFleetVehicleId'] = pendingFleetVehicleId
    }
    return this.http.post<any>(`/FleetVehicle`, dto, {
      params,
    })
  }
}

function setParams(
  params: HttpParams,
  findOptions: AssetsFindOptions
): HttpParams {
  Object.keys(findOptions).forEach((key) => {
    const value = findOptions[key]
    if (value !== null && value !== 'ALL') {
      if (key === 'sort') {
        params = params.append(key, JSON.stringify(value))
      } else {
        params = params.append(key, value)
      }
    }
  })

  return params
}

function getFilenameFromContentDisposition(header: string | null): string {
  return header?.split('filename=')[1]?.split(';')[0] ?? ''
}

export interface BulkUploadResponseError {
  assetIdentifier: string
  rowNumber: number
  error: { code: string; message: string }
}

export interface BulkUploadResponse {
  fleetVehicles: any[]
  errors: BulkUploadResponseError[]
  createdCount: number
  updatedCount: number
  archivedCount: number
}
